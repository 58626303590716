
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from "react";
import Script from "next/script";
import "styles/globals.css";
import { ThemeProvider } from "contexts/ThemeContext";

const __Page_Next_Translate__ = function App({ Component, pageProps }) {
  return (
    <ThemeProvider>
      {process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID && (
        <>
          {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
              lazyOnload
            />
          )}
          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){ dataLayer.push(arguments); }
                  gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                  gtag('js', new Date());
                  gtag("consent", "default", {
                    ad_storage: "denied",
                    analytics_storage: "denied"
                  });
                `,
            }}
            lazyOnload
          />
          <Script
            id="axeptio-injector"
            dangerouslySetInnerHTML={{
              __html: `
              if (typeof window.axeptioSettings === "undefined") {
                window.axeptioSettings = {
                  clientId: "${process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID}",
                  cookiesVersion: "${process.env.NEXT_PUBLIC_AXEPTIO_COOKIES_VERSION}",
                };
                
                (function(d, s) {
                  let t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                  e.async = true; e.src = "//static.axept.io/sdk-slim.js";
                  t.parentNode.insertBefore(e, t);
                })(document, "script");
              }
            `,
            }}
          />
          <Script
            id="axeptio-process"
            dangerouslySetInnerHTML={{
              __html: `
                void 0 === window._axcb && (window._axcb = []);
                window._axcb.push(function(axeptio) {
                  axeptio.on("cookies:complete", function(choices) {
                    if (choices.google_analytics) {
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){ dataLayer.push(arguments); }
                      gtag('js', new Date());
                      gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                    }
                  })
                })
            `,
            }}
          />
        </>
      )}
      <Component {...pageProps} />
    </ThemeProvider>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  